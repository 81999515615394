import React from 'react';
import Layout from '../../../layouts/index';

import Success from '../../../components/Success';
import featureSwitch from '../../../services/featureSwitch';
import SuccessRetail from '../../../components/SuccessRetail';

const SuccessContainer = () => (
  <Layout slug="Success">
    {featureSwitch('retailShopId') ? <SuccessRetail /> : <Success />}
  </Layout>
);

export default SuccessContainer;
