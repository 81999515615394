import React from 'react';
import PropTypes from 'prop-types';
import Link from 'gatsby-link';
import styled from 'styled-components';

import { trackEvent } from '../services/analytics';
import withOrderContext from '../withOrderContext';

import OrderContainer from '../pagesComponents/common/OrderContainer';
import {
  mobileThresholdPixels, colors, Wrapper, Block, PageTitle,
}
  from './order/styledComponents';
import android from '../assets/store.android.svg';
import iOS from '../assets/store.ios.svg';
import message from '../assets/message.svg';

const TextLink = styled.span`
  color: ${colors.navy};
  text-decoration: underline;
  cursor: pointer;
`;

const P = styled.p`
  color: ${colors.navy};
  text-align: center;
  padding: 20px;
  font-weight: 300;
  margin: 20px 0px 0px;

  @media (max-width: ${mobileThresholdPixels}) {
    padding: 10px 0px;
    font-size: 12px;
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -80px;

  @media (max-width: ${mobileThresholdPixels}) {
    margin-top: -40px;
    padding: 0px 25px;
  }
`;

const Image = styled.img`
  margin-bottom: 40px;
`;

const Icon = styled.img`
  margin: 10px;
`;

const WhiteBlock = styled.div`
  background-color: ${colors.white};
  margin-top: 70px;
  margin-bottom: 50px;
  padding: 30px 180px;

  @media (max-width: ${mobileThresholdPixels}) {
    padding: 20px;
    margin: 20px;
  }
`;

const Text1 = styled(P)`
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  padding: 20px;

  @media (max-width: ${mobileThresholdPixels}) {
    padding: 20px;
  }
`;

const Text2 = styled(P)`
  font-size: 14px;
  line-height: 16px;
  padding: 0px 20px 50px;
`;

const Row = styled.div`
  text-align: center;
  margin-bottom: 10px;
`;

function reinitOrder(trackingOriginElement, reinit) {
  trackEvent('click', trackingOriginElement);
  reinit();
}

const Success = ({ orderContext: { reinit } }) => (
  <OrderContainer>
    <Wrapper>
      <Block>
        <PageTitle>
          La commande a bien été enregistrée
        </PageTitle>

        <Row>
          <Image src={message} />
        </Row>

        <Link
          to="/"
          style={{ flex: 1, textAlign: 'center' }}
          onClick={() => reinitOrder('home_success', reinit)}
        >
          <TextLink>retour à l‘accueil</TextLink>
        </Link>
      </Block>
    </Wrapper>
  </OrderContainer>
);

Success.propTypes = {
  orderContext: PropTypes.shape({
    reinit: PropTypes.func.isRequired,
    rdv1Location: PropTypes.string,
  }).isRequired,
};

export default withOrderContext(Success);
