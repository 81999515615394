import React from 'react';

import Layout from '../../layouts/index';
import SuccessContainer from './containers/SuccessContainer';
import MenuBar from '../../components/home/MenuBar';

const Step4 = () => (
  <Layout routeSlug="Step4">
    <MenuBar shouldReinit />
    <SuccessContainer />
  </Layout>
);

export default Step4;
